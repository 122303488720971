 /**  引用方法
  <CountDown v-slot="timeObj" :time="100" timingType="2">
    <div class="count-down">
      <div class="icon"></div>
      {{timeObj.d}}天{{timeObj.hh}}小时{{timeObj.mm}}分钟{{timeObj.ss}}秒
    </div>
  </CountDown>
 */
<template>
  <div class="">
    <span v-if="timingType == 1">{{timeNumber}}</span>
    <div class="_base-count-down" v-if="timingType == 2">
      <div class="content">
        <slot v-bind="{
        d: days, h: hours, m: mins, s: seconds,
        hh: `00${hours}`.slice(-2),
        mm: `00${mins}`.slice(-2),
        ss: `00${seconds}`.slice(-2),
      }">
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    days: '0',
    hours: '00',
    mins: '00',
    seconds: '00',
    curTime: 0,

    timer: null,  //倒计时定时器名称
    timeNumber: 0,
    timeSuspend: true, //是否暂停
  }),
  props: {
    timingType: { //倒计时类型
      type: [Number, String],
      default: 1,
    },
    isSuspend: { //是否暂停
      type: Boolean,
      default: true,
    },
    time: { //剩余时间  默认 s
      type: [Number, String],
      default: 0
    },
    refreshCounter: {  //更新计时间器
      type: [Number, String],
      default: 0
    },
    end: {    //到期时间
      type: [Number, String],
      default: 0
    },
    isMiniSecond: {  //是否毫秒
      type: Boolean,
      default: false
    }
  },
  computed: {
    duration () {
      if (this.timingType == 2) {
        if (this.end) {
          let end = String(this.end).length >= 13 ? +this.end : +this.end * 1000;
          end -= Date.now();
          return end;
        }
        const time = this.isMiniSecond ? Math.round(+this.time / 1000) : Math.round(+this.time);
        return time;
      }
    }
  },
  mounted () {
    this.timeSuspend = this.isSuspend
    this.countDown();
  },
  watch: {
    isSuspend (val) {
      this.timeSuspend = val
      if (val) {
        if (this.timingType == 1) {
          this.getFinalTime()
        } else if (this.timingType == 2) {
          this.getTime(this.timeNumber);
        }
      } else {
        this.stopTiming()
      }
    },
    duration () {
      this.countDown();
    },
    refreshCounter () {
      this.countDown();
    }
  },
  beforeDestroy () {
    this.timeSuspend = false
  },
  methods: {
    countDown () {
      if (this.timingType == 1) {
        this.timeNumber = this.time
        this.timeSuspend && this.getFinalTime()
      } else if (this.timingType == 2) {
        // eslint-disable-next-line no-unused-expressions
        this.curTime = Date.now();
        this.timeSuspend && this.getTime(this.duration);
      }
    },
    //数字格式
    getFinalTime () {
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.timeNumber <= 0) {
          this.$emit('callback')
          return;
        }
        this.timeNumber--
        this.timeSuspend && this.getFinalTime()
      }, 1000);
    },
    //日期格式
    getTime (time) {
      // eslint-disable-next-line no-unused-expressions
      this.timer && clearTimeout(this.timer);
      if (time < 0) {
        return;
      }
      // eslint-disable-next-line object-curly-newline
      const { dd, hh, mm, ss } = this.durationFormatter(time);
      this.days = dd || 0;
      // this.hours = `00${hh || ''}`.slice(-2);
      // this.mins = `00${mm || ''}`.slice(-2);
      // this.seconds = `00${ss || ''}`.slice(-2);
      this.hours = hh || 0;
      this.mins = mm || 0;
      this.seconds = ss || 0;
      this.timer = setTimeout(() => {
        const now = Date.now();
        const diffTime = Math.floor((now - this.curTime) / 1000);
        const step = diffTime > 1 ? diffTime : 1; // 页面退到后台的时候不会计时，对比时间差，大于1s的重置倒计时
        this.curTime = now;
        this.timeNumber = time - step
        this.timeSuspend && this.getTime(time - step);
      }, 1000);
    },
    durationFormatter (time) {
      if (!time) return { ss: 0 };
      let t = time;
      const ss = t % 60;
      t = (t - ss) / 60;
      if (t < 1) return { ss };
      const mm = t % 60;
      t = (t - mm) / 60;
      if (t < 1) return { mm, ss };
      const hh = t % 24;
      t = (t - hh) / 24;
      if (t < 1) return { hh, mm, ss };
      const dd = t;
      return { dd, hh, mm, ss };
    },
  }
};
</script>
<style lang='less' scoped>
._base-count-down {
  color: #000;
  text-align: left;
  position: relative;
  .content {
    width: auto;
    display: flex;
    align-items: center;
  }
}
</style>